import { useEffect, useState } from 'react';
import { AssetType, DailyCycleCount } from '../types/types';
import { format } from 'date-fns';
import { toNumber } from 'lodash';
import { DAILY_CYCLE_COUNT } from '../config';
import { useWebSockets } from 'src/contexts/webSocketsContext/websockets.context';

type useCycleCountDataTypes = {
  customerId: string | null;
  siteId: string | null;
  asset: AssetType;
};

const formatCycleCount = (cycleCount: any) => {
  if (!cycleCount || Number.isNaN(toNumber)) return 0;
  return Number(cycleCount.value);
};

export const useCycleCountData = ({ customerId, siteId, asset }: useCycleCountDataTypes) => {
  const [dailyCycleCounts, setDailyCycleCounts] = useState<DailyCycleCount[] | null>(null);
  const assetTag = asset?.assignedTags?.find(
    (tag) => tag?.tagName.toLowerCase() === DAILY_CYCLE_COUNT
  );
  const { messages } = useWebSockets();

  const latestMessage = messages[messages.length === 0 ? 0 : messages.length - 1];
  const gatewayInputs = latestMessage?.data?.gateway_inputs;
  const timestamp = latestMessage?.data?.timestamp;
  const findInput = gatewayInputs?.find(
    (input: { id: string | undefined }) => input.id === assetTag?.assignedTagId
  );
  const gatewayId = assetTag?.assignedGatewayId;

  useEffect(() => {
    if (gatewayId && findInput && timestamp) {
      const date = format(new Date(timestamp * 1000), 'MM.dd');
      const cycleCountValue = formatCycleCount(findInput);
      setDailyCycleCounts((prev) => {
        if (prev === null) {
          return [{ day: date, value: cycleCountValue }];
        }
        const cycleCounts = [...prev];
        cycleCounts[cycleCounts.length - 1] = { day: date, value: cycleCountValue };
        return cycleCounts;
      });
    }
  }, [gatewayId, findInput, timestamp]);

  useEffect(() => {
    if (customerId && gatewayId) {
      setDailyCycleCounts(null);
      return;
    }
    setDailyCycleCounts([]);
  }, [customerId, siteId, gatewayId, assetTag?.assignedTagId]);

  return { dailyCycleCounts };
};
